<template>
  <v-app>
    <div v-if="this.questions != null">
      <div class="home" v-if="!$vuetify.breakpoint.xs">
        <div class="resultsHeader">
          <h1 class="ma-0">Results</h1>
          <v-spacer></v-spacer>
          <img src="@/assets/bear.png" style="height:160px;" alt="">
        </div>
      </div>

      <div class="greetingContainerMobile" v-if="$vuetify.breakpoint.xs">
        <h1 class="ma-0">Results</h1>
      </div>

      <div class="content">


        <div class="centreContent">
          <h3 v-if="this.result == 'fail'">You didn’t pass this time, but that’s okay!</h3>
          <h3 v-if="this.result != 'fail'">Well done, you passed! <Emoji name="partyface" /></h3>
          <br class="mobile-break">
          <h3 v-if="this.result == 'fail'">Take time to review your answers and try again <Emoji name="rosycheeksmile" /></h3>
          <h3 v-if="this.result != 'fail'">Take time to review your answers:</h3>
        </div>


        <v-card class='pa-2 mb-2 mobileCards' max-width="1000" :class="getBorderColour(question[3].toLowerCase(), question[4].toLowerCase())" v-for="question in questions" :key="question.englishTranslation">

          <div class="grid-container">
            <div>
              <div class="resultIconContainer" style="border-radius:50%" :class="getBackgroundColour(question[3].toLowerCase(), question[4].toLowerCase())">
                <v-icon dark small color="#f28482" v-if="question[3].toLowerCase() != question[4].toLowerCase()">
                  mdi-close
                </v-icon>

                <v-icon dark small color="#90be6d" v-if="question[3].toLowerCase() == question[4].toLowerCase()">
                  mdi-check
                </v-icon>
              </div>
            </div>

            <div>

              <template>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>{{ question[0] }}</td>
                      </tr>
                      <tr v-if="question[1] != ''">
                        <td>{{ question[1] }}</td>
                      </tr>
                      <tr v-if="question[2] != ''">
                        <td><i>{{ question[2] }}</i></td>
                      </tr>
                      <tr>
                        <td>Correct answer: {{ question[3] }}</td>
                      </tr>
                      <tr v-if="question[3].toLowerCase() != question[4].toLowerCase()">
                        <td>You answered: {{ question[4] }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

            </div>
          </div>

        </v-card>

        <div class="centreContent">
          <router-link to="/dashboard"> <v-btn x-large class="ma-2" color="#90be6d" elevation=0 dark>Continue</v-btn> </router-link>
        </div>

      </div>
    </div>
  </v-app>
</template>

<script>
import {db} from '../firebase/db';
import firebase from 'firebase';
import Emoji from '@/components/Emoji.vue'

export default {
  title: "Results",
  components: {
      Emoji
    },
  props: ['lesson', 'result', 'questions', 'username', 'uid'],
  data() {
    return {
      signedIn: false,
      greeting: "Привет",
      userData: [],
      lessonOrSession: "session"
    }
  },
  methods: {
    getBackgroundColour(correct, answered) {
        return (correct == answered) ? 'colour-green' : 'colour-red'
      },

      getBorderColour(correct, answered) {
        return (correct == answered) ? 'border-green' : 'border-red'
      }
  },
  async beforeMount() {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.user = user;
        this.uid = user.uid;
        this.username = user.displayName;
        this.signedIn = true

      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });

    if (this.lesson != null) {
      this.lessonOrSession = "lesson"
    }

    this.userData = await db.collection('Users').doc(this.uid).get();
    this.userData = this.userData.data();
    console.log(this.userData);



    console.log("RESULT: " + this.result);
    console.log("LESSON: " + this.lesson);
    console.log("");

    if (this.lesson != null && this.result == 'pass') {
      var cases = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst']

      console.log(this.userData.lessonsCompleted);
      console.log(this.userData.lessonsCompletedAdjectives);

      var adjLessons = ['2', '4', '6', '7']
      var nounLessons = ['1', '3', '5']

      var lessonCase = this.lesson.substring(0, this.lesson.length - 1)
      var lessonNumber = this.lesson.substring(this.lesson.length - 1, this.lesson.length)
      console.log(lessonCase + ", " + lessonNumber);

      if (lessonCase == 'nom') {

        if (lessonNumber == '1') {

          this.userData.lessonsCompleted[0] = -1
          await db.collection('Users').doc(this.uid).update({
            lessonsCompleted: this.userData.lessonsCompleted,
          })

        } else if (lessonNumber == '2') {

          this.userData.lessonsCompleted[0] = 3
          await db.collection('Users').doc(this.uid).update({
            lessonsCompleted: this.userData.lessonsCompleted,
          })

        } else if (lessonNumber == '3') {

          this.userData.lessonsCompletedAdjectives[0] = 4
          await db.collection('Users').doc(this.uid).update({
            lessonsCompletedAdjectives: this.userData.lessonsCompletedAdjectives
          })

        }


      } else if (adjLessons.includes(lessonNumber)) {
        console.log("ADJECTIVE");
        if (this.userData.lessonsCompletedAdjectives[cases.indexOf(lessonCase)] < adjLessons.indexOf(lessonNumber) + 1) {
          this.userData.lessonsCompletedAdjectives[cases.indexOf(lessonCase)]++
          console.log("UPDATING ADJ");
          await db.collection('Users').doc(this.uid).update({
            lessonsCompletedAdjectives: this.userData.lessonsCompletedAdjectives
          })
        } else {
          console.log("NO NEED TO UPDATE");
        }
      } else {
        console.log("NOUN");
        if (this.userData.lessonsCompleted[cases.indexOf(lessonCase)] < nounLessons.indexOf(lessonNumber) + 1) {
          this.userData.lessonsCompleted[cases.indexOf(lessonCase)]++
          console.log("UPDATING NOUN");

          await db.collection('Users').doc(this.uid).update({
            lessonsCompleted: this.userData.lessonsCompleted
          })
        } else {
          console.log("NO NEED TO UPDATE");
        }
      }
    }









    var today = new Date()
    today.setHours(0,0,0,0)


    // var caseAbbreviations = ['nom', 'gen', 'acc', 'dat', 'inst', 'prep'];
    var genderPPNames = ['Masc', 'Fem', 'Neut', 'Plu'];
    var genderInitials = ['m', 'f', 'n', "p"];

    //PAST PERFORMANCE
    for (var i = 0; i < this.questions.length; i++) {

      if (this.questions[i][6] == "IdentifyGender") {
        if (this.userData.identifyGenderPastPerformance.length == 100) {
          this.userData.identifyGenderPastPerformance.shift();
        }
        if (this.questions[i][3] == this.questions[i][4]) {
          this.userData.identifyGenderPastPerformance.push(1)
        } else {
          this.userData.identifyGenderPastPerformance.push(0)
        }

        //Redo this so it only has 1 update total, not each time. Easy
        await db.collection('Users').doc(this.uid).update({
          identifyGenderPastPerformance: this.userData.identifyGenderPastPerformance
        })

      } else {
        var pastPerformanceArray = this.questions[i][5] + genderPPNames[genderInitials.indexOf(this.questions[i][6])] + this.questions[i][7] + "PastPerformance";
        console.log(i + " | " + pastPerformanceArray);

        //Queue: only hold max 100 values
        if (this.userData[pastPerformanceArray].length == 100) {
          this.userData[pastPerformanceArray].shift();
        }
        if (this.questions[i][3] == this.questions[i][4]) {
          this.userData[pastPerformanceArray].push(1)
        } else {
          this.userData[pastPerformanceArray].push(0)
        }

        //Redo this so it only has 1 update total, not each time. Easy
        await db.collection('Users').doc(this.uid).update({
          [pastPerformanceArray]: this.userData[pastPerformanceArray]
        })
      }



    }





    //FORGETTING CURVE
    for (i = 0; i < this.questions.length; i++) {

      if (this.questions[i][6] == "IdentifyGender") {

        //decayFactor, lastReset, resetCounter

        if (this.questions[i][3] == this.questions[i][4]) {
          this.userData.identifyGenderForgettingCurve[2]++;
        }

        if (this.userData.identifyGenderForgettingCurve[0] == -999) {
          //First attempt at this case/gender combo -- set initial decay factor
          this.userData.identifyGenderForgettingCurve[0] = 0.3;
          //And set initial reset to today
          this.userData.identifyGenderForgettingCurve[1] = today.toDateString();
          //And set reset counter to 0
          this.userData.identifyGenderForgettingCurve[2] = 0;


        } else if (this.userData.identifyGenderForgettingCurve[2] == 10) {
          //Decay factor
          this.userData.identifyGenderForgettingCurve[0] = (this.userData.identifyGenderForgettingCurve[0] * 0.6); //this surely has the potential to decrease decay factor too quickly
          //Last reset = now
          this.userData.identifyGenderForgettingCurve[1] = today.toDateString();
          //Reset reset counter
          this.userData.identifyGenderForgettingCurve[2] = 0;
        }
        //this.questions[i]

        //Redo this so it only has 1 update total, not each time. Easy
        await db.collection('Users').doc(this.uid).update({
          identifyGenderForgettingCurve: this.userData.identifyGenderForgettingCurve
        })



      } else {
        var combo = this.questions[i][6] + this.questions[i][7]

        var forgettingCurveArray = this.questions[i][5] + "ForgettingCurve";
        //decayFactor, lastReset, resetCounter ...
        //for each ^, mascNoun, mascAdj, femNoun, femAdj, neutNoun, neutAdj, pluAdj
        var forgettingCurveFindIndex = ['mNoun', 'X', 'C', 'mAdj', 'X', 'C', 'fNoun', 'X', 'C', 'fAdj', 'X', 'C', 'nNoun', 'X', 'C', 'nAdj', 'X', 'C', 'pAdj', 'X', 'C'];

        var index = forgettingCurveFindIndex.indexOf(combo)


        //If correct answer
        if (this.questions[i][3] == this.questions[i][4]) {
          this.userData[forgettingCurveArray][index + 2]++;
        }

        if (this.userData[forgettingCurveArray][index] == -999) {
          //First attempt at this case/gender combo -- set initial decay factor
          this.userData[forgettingCurveArray][index] = 0.3;
          //And set initial reset to today
          this.userData[forgettingCurveArray][index + 1] = today.toDateString();
          //And set reset counter to 0
          this.userData[forgettingCurveArray][index + 2] = 0;


        } else if (this.userData[forgettingCurveArray][index + 2] == 10) {
          //Decay factor
          this.userData[forgettingCurveArray][index] = (this.userData[forgettingCurveArray][index] * 0.6); //this surely has the potential to decrease decay factor too quickly
          //Last reset = now
          this.userData[forgettingCurveArray][index + 1] = today.toDateString();
          //Reset reset counter
          this.userData[forgettingCurveArray][index + 2] = 0;
        }
        //this.questions[i]

        //Redo this so it only has 1 update total, not each time. Easy
        await db.collection('Users').doc(this.uid).update({
          [forgettingCurveArray]: this.userData[forgettingCurveArray]
        })
      }



    }









    //Update weekly Streak
    var d = new Date(); // current time
    var currentDay = d.getDay();
    today = new Date()
    this.userData.weeklyStreak[currentDay] = today.toDateString();



    today.setHours(0,0,0,0)
    // var todayString = today;
    // console.log(todayString);



    //totalStreak, lastPractice

    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    // console.log("yesterday: " + yesterday.toDateString());
    // console.log("last prac: " + this.userData.lastPractice);


    // console.log("TOTAL STREAK (current): " + this.userData.totalStreak);
    if (this.userData.lastPractice == yesterday.toDateString()) {
      // console.log("YES, LAST PRACTICE YESTERDAY");
      this.userData.totalStreak++;
    } else if (this.userData.lastPractice == null) {
      // console.log("YES, LAST PRACTICE YESTERDAY");
      this.userData.totalStreak++;
    } else if (this.userData.lastPractice == !yesterday.toDateString() && this.userData.lastPractice == !today.toDateString()) {
      this.userData.totalStreak = 1;
    }
    // console.log("TOTAL STREAK (updated): " + this.userData.totalStreak);


    await db.collection('Users').doc(this.uid).update({
      weeklyStreak: this.userData.weeklyStreak,
      totalStreak: this.userData.totalStreak,
      lastPractice: today.toDateString()
    })




  }
}
</script>


<style media="screen">

  h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 0px solid #dddddd;
    text-align: center;
    padding: 8px;
  }

  .home {
    height: 180px;
    text-align: left;
    background-color: #383838;
    position: relative;
    align-content: center;
    display: flex;
    align-items: center;
  }

  .resultsHeader {
    color: #fff;
    width: calc(100vw - 40px);
    max-width: 1000px;
    font-size: 1.2em;
    top: 50%;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .content {
    padding: 20px 0 0 0;
    text-align: left;
    max-width: 1000px;
    margin: auto;
  }

  .border-green {
    border: 3px solid #90be6d !important;
    padding: 0;
    margin: 0;
  }

  .colour-green {
    background-color: #d8f3dc !important;
  }

  .border-red {
    border: 3px solid #f28482 !important;
    padding: 0;
    margin: 0;
  }

  .colour-red {
    background-color: #f5cac3 !important;
  }

  .resultIconContainer {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    float:left;
    margin: 0;
    display:inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }

  .centreContent {
    padding: 20px 0;
    text-align: center;
    margin: auto;
  }

  .resultCard {
    max-width: 1000px;
  }


  .grid-container {
    margin: auto;
    justify-content: center;
    display: grid;
    grid-template-columns: auto;
    padding: 0px 20px;
  }

  .grid-container > div {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-content: center;
  }

  .mobile-break {
    display: none;
  }

</style>







<style scoped>
  @media screen and (max-width: 1024px) {

    .mobile-break {
      display: inline;
    }

    h1 {
      font-size: 2em;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 1em;
    }

    .greetingContainerMobile {
      height: auto;
      background-color: #383838;
      color: #fff;
      text-align: left;
      padding: 10px;
    }

    .centreContent {
      padding: 0 10px 10px 10px;
    }

    .mobileCards {
      margin: 0 10px;
    }

    .resultIconContainer {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #fff;
      float:left;
      margin: 0;
      display:inline-flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-align: center;
      padding: 0;
    }


  }
</style>











<!--  -->
